// helper variables





// helper classes

.img-responsive-fix {
	width: 100%;
	max-width: 100%;
}

.border-white {
	border: solid 1px #fff;
}

.pad-none {
	padding: 0px !important;
}

// padding classes

.pad-sm-all {
	padding: 10px;
}
.pad-sm-above {
	padding-top: 10px;
}
.pad-sm-below {
	padding-bottom: 10px;
}
.pad-sm-left {
	padding-left: 10px;
}
.pad-sm-right {
	padding-right: 10px;
}
.pad-md-all {
	padding: 20px;
}
.pad-md-above {
	padding-top: 20px;
}
.pad-md-below {
	padding-bottom: 20px;
}
.pad-md-left {
	padding-left: 20px;
}
.pad-md-right {
	padding-right: 20px;
}
.pad-lg-all {
	padding: 40px;
}
.pad-lg-above {
	padding-top: 40px;
}
.pad-lg-below {
	padding-bottom: 40px;
}
.pad-lg-left {
	padding-left: 40px;
}
.pad-lg-right {
	padding-right: 40px;
}

// margin classes

.margin-sm-all {
	margin: 10px;
}
.margin-sm-above {
	margin-top: 10px;
}
.margin-sm-below {
	margin-bottom: 10px;
}
.margin-sm-left {
	margin-left: 10px;
}
.margin-sm-right {
	margin-right: 10px;
}
.margin-md-all {
	margin: 20px;
}
.margin-md-above {
	margin-top: 20px;
}
.margin-md-below {
	margin-bottom: 20px;
}
.margin-md-left {
	margin-left: 20px;
}
.margin-md-right {
	margin-right: 20px;
}
.margin-lg-all {
	margin: 40px;
}
.margin-lg-above {
	margin-top: 40px;
}
.margin-lg-below {
	margin-bottom: 40px;
}
.margin-lg-left {
	margin-left: 40px;
}
.margin-lg-right {
	margin-right: 40px;
}

.desktop-pull-right {
	@media screen and (min-width: @screen-sm-min) {
		.pull-right();
	}
}

.topradius {
	.border-top-radius(@border-radius-base);
}
.bottomradius {
	.border-bottom-radius(@border-radius-base);
}
.rightradius {
	.border-right-radius(@border-radius-base);
}
.leftradius {
	.border-left-radius(@border-radius-base);
}
.allradius {
	border-radius: @border-radius-base;
}

.shadow {
	.box-shadow(1px 1px 5px 0px rgba( 0, 0, 0, 0.3 ));
}
.inner-shadow {
	.box-shadow(inset -3px -3px 5px 0px rgba( 0, 0, 0, 0.3 ));
}
.shadow-both {
	box-shadow: 1px 1px 5px 0px rgba( 0, 0, 0, 0.3 ), inset -3px -3px 5px 0px rgba( 0, 0, 0, 0.3 );
}
.text-shadow {
	text-shadow: -1px -1px 2px rgba(0,0,0, 0.3);
}
.font-large {
	font-size: @font-size-large;
}