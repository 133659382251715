// site less here

// variables

@grey-dark: #363636;

@grey-mid-low: #636363;
@grey-mid: #797979;
@grey-light: #9a9a9a;
@grey-lighter: #bbbbbb;
@grey-lightest: #cecece;

@blue: #434d78;

@brand-light: #8b90ac;



// generic classes
a {
	transition: all .4s;
	&:hover {
		text-decoration: none;
	}

	button {
		pointer-events: none;
	}
}

p {
	&.lead {
		font-size: 20px;
	}
}

h1, h2, h3 {
	text-transform : uppercase;
	font-weight: 300;
}

body {
	font-family: 'open-sans', 'helvetica', 'arial', sans-serif;
	font-weight: 400;
	color: @grey-mid-low;
	@media screen and (min-width: @screen-sm-min) {
		padding-top: 197px;
	}
}

.btn-outline {
	text-transform: uppercase;
	border: solid 2px @grey-mid;
	border-radius: 30px;
	padding: 8px 26px;
}

// site specific structure

#header {
	background: #fff;
	.pad-lg-above();
	width: 100%;
	z-index: 2000;
	box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	transition: all .4s;
	.social {
		.make-sm-column(6);
		.pull-right();
		.text-right();
		.social-item {
			padding-right: 10px;
			@media screen and (max-width: 480px) {
				.text {
					display: none;
				}
			}
		}
		a {
			display: inline-block;
			position: relative;
			color: @grey-mid;
			vertical-align: middle;
			height: 30px;
			padding-right: 40px;
			padding-top: 5px;
			margin-bottom: 5px;
			
			&.icon-wrap {
				position:relative;
				border-radius: 100%;
				width: 30px;
				padding-left: 0px;
				padding-right: 0px;
				height: 30px;
				background-color: @brand-light;
				color: #fff;
				right: 0px;
				top: 0px;
				transition: all .4s;
				.fa {
					position: absolute;
					line-height: 1;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				&:hover {
					background-color: @grey-dark;
					color: #fff;
				}
			}
			&:hover {
				color: @grey-dark;
				.icon-wrap {
					background-color: @grey-dark;
				}
			}
			.icon-wrap {
				position: absolute;
				border-radius: 100%;
				width: 30px;
				height: 30px;
				background-color: @brand-light;
				color: #fff;
				right: 0px;
				top: 0px;
				transition: all .4s;
				.fa {
					position: absolute;
					line-height: 1;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			&:hover {
				color: @grey-dark;
				.icon-wrap {
					background-color: @grey-dark;
				}
			}
		}
		@media screen and (max-width: @screen-xs-max) {
			a { 
				display: inline-block;
			}
			.pad-sm-above();
			.text-noshow {
				display: none;
			}
		}
	}
	.logo {
		.make-sm-column(6);
		a {
			display: inline-block;
		}
		img {
			width: auto;
			height: 81px;
			transition: all .4s;
		}
		@media screen and (max-width: @screen-xs-max) {
			display: none;
		}
	}
	@media screen and (max-width: @screen-xs-max) {
		.pad-sm-above();
		.social-item {
			display: inline-block;
			//padding-left: 10px;
		}
	}
	@media screen and (min-width: @screen-sm-min) {
		position: fixed;
		transform: translateZ(0);
		top: 0px;

		&.page-scrolled {
			padding-top: 20px;
			.logo {
				img {
					height: 30px; 
					width: auto;
					transition: all .8s;
				}
			}
			.social-item {
				display: inline-block;
				.text-noshow {
					display: none;
				}
			}
		}
	}
}

.navbar {
	margin-left: -15px !important;
	margin-right: -15px !important;
	margin-bottom: 0px;

	a {
		color: @grey-mid;
		&:hover {
			color: @grey-dark;
			//background-color: transparent !important;
		}
	}
}
.navbar-header {
	.navbar-brand {
		img {
			width: auto;
			height: 30px;
		}
	}
	.icon-bar {
		background-color: @grey-dark;
	}
	@media screen and (min-width: @screen-sm-min) {
		display: none;
	}
}
.navbar-collapse {
	margin-left: -15px !important;
	margin-right: -15px !important;
}
.caret {
	position: absolute;
	left: 50%;
	bottom: 10px;
	margin-left: -5px;
	@media screen and (max-width: @screen-xs-max) {
		position: absolute;
		left: auto;
		right: 30px;
		bottom: 20px;
	}
}

.hero {
	position: relative;
	width: 100%;
	&>button {
		border: none;
		position: absolute;
		color: #fff;
		padding: 20px;
		height: 70px;
		z-index: 1000;
		background: transparent;
		font-size: 24px;
		text-shadow: 1px 1px 5px rgba(0,0,0,0.4);
		&:hover {
			color:#efefef;
		}
		&:active, &:focus {
			outline: 0 !important;
		}
		&.hero-nav-left {
			left: 0px;
			top: 50%;
			margin-top: -35px;
		}
		&.hero-nav-right {
			right: 0px;
			top: 50%;
			margin-top: -35px;
		}
	}
	.hero-item {
		display: none;
		position: relative;
		width: 100%;
		.hero-overlay{
			position:absolute;
			width: 100%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
			text-align: center;
			.text-wrap {
				background-color: rgba(42,42,42,.8);
				padding: 10px 20px 20px;
				display: inline-block;
				border-radius: @border-radius-base;
				@media screen and (max-width: @screen-xs-max) {
					display: none;
				}
			}
			.title {
				font-size: 28px;
				font-weight: 500;
			}
			.copy {
				font-size: 16px;
				@media screen and (max-width: @screen-xs-max) {
					display: none;
				}
			}
		}
		&:first-of-type {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
			

		}
	}
	.slick-dots {
		position: absolute;
		bottom: 0px;
		background-color: rgba(42,42,42,.8);
		margin-bottom: 0px;
		padding: 15px 0px;
		li {
			button {
				&:before {
					font-family: 'fontawesome';
					color: #fff;
					opacity: .4;
					font-size: 10px;
					content: '';
				}
			}
			&.slick-active {
				button {
					&:before {
						color: #fff;
						opacity: 1;
						content: '';
					}
				}
			}
		}
		@media screen and (max-width: @screen-xs-max) {
			position: relative;
		}
	}
	&.slick-slider {
		margin-bottom: 0px;
	}
}

.strapline {
	color: @grey-light;
	font-size: 18px;
	font-weight: 300;
	padding: 30px 0px;
	text-align: center;
}

.central {
	//background: @grey-light;
	// f3f3f3
	// dcdcdc
	#gradient > .vertical(#f3f3f3, #e3e3e3); 
	padding-top: 60px;
	padding-bottom: 60px; 

	table {
		.table();
		img {
			min-width: 82px;
		}
	}

	img {
		//.img-responsive();
		max-width: 100%;
		height: auto;
	}

}

.sector {
  overflow: hidden;
  line-height: 1;
  border-radius: 3px;
  a {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 85%;
    background-size: cover;
    overflow: hidden;
    border-radius: 3px;
    .btn {
    	color: @grey-dark;
    	text-transform: uppercase;
    }
    .overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.7);
      z-index: 1;
      //display: none;
      opacity: 0.3;
      transition: all .4s;
      border-radius: 3px;
    }
    .sectortitle {
    	color: #fff;
    	position: absolute;
    	left: 20px;
    	top: 20px;
    	z-index: 10;
    	font-size: 22px;
    	@media screen and (min-width: @screen-sm-min) {
        	//font-size: 22px;
        	left: 20px;
        	top: 20px;
        	font-size: 26px;
      	}
      	@media screen and (min-width: @screen-md-min) {
        	//font-size: 26px;
        	left: 25px;
        	top: 25px;
        	font-size: 28px;
      	}
      	@media screen and (min-width: @screen-lg-min) {
        	//font-size: 32px;
        	left: 30px;
        	top: 30px;
        	font-size: 30px;
      	}
    }
    .sectorcontent {
      color: #fff;
      position: absolute;
      left: 20px;
      top: 50px;
      z-index: 10;
      //display: none;
      opacity: 0;
      transition: all .4s;
      width: 100%;
      padding-right: 40px;
      line-height: 1.5;
      font-size: 13px;
      button {
  			display: none;
  		}

      @media screen and (min-width: @screen-sm-min) {
        //font-size: 22px;
        left: 20px;
      	top: 50px;
      	padding-right: 40px;
      	line-height: 1.5;
      	font-size: 13px;
      }
      @media screen and (min-width: @screen-md-min) {
        //font-size: 26px;
        left: 25px;
        top: 55px;
        padding-right: 50px;
        line-height: 1.5;
        font-size: 14px;
        button {
        	display: block;
        }
      }
      @media screen and (min-width: @screen-lg-min) {
        //font-size: 32px;
        left: 30px;
      	top: 75px;
      	padding-right: 60px;
      	line-height: 1.7;
      	font-size: 14px;
      }
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
    }
    &:hover {
      .overlay, .sectorcontent {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: @screen-xs-max) {
  	.pad-md-below();

  	.sectorcontent {
  		
  	}
  }
  
}

.clients {
	padding-top: 60px;
	padding-bottom: 60px;
}

.client-list {
	.pad-md-above();
	&>div {
		
	}
	img {
		.img-responsive();
		.center-block();
		filter: grayscale(1);
		opacity: 0.5;
		transition: all .4s;
		&:hover {
			filter: grayscale(0);
			opacity: 0.8;
			transition: all .4s;
		}
	}
}

.news {
	padding-top: 60px;
	padding-bottom: 60px;
	background: @blue;
	color: #fff;
	text-align: center;

	.news-item {
		.make-xs-column(10);
		.make-xs-column-offset(1);
		.make-sm-column(4);
		.pad-lg-below();

		&:nth-of-type(odd) {
			.make-sm-column-offset(2);
		}
		&:nth-of-type(even) {
			.make-sm-column-offset(1);
		}
	}

	a.news-title {
		color: #fff;
		display: block;
		font-weight: 700;
		padding-bottom: 5px;
	}
	a.news-body {
		color: @grey-lightest;
		display: block;
		padding-bottom: 5px;
		line-height: 1.6;
		&:hover {
			color: #fff;
		}
	}
	.news-date {
		color: @grey-lighter;
	}

	a.btn-outline {
		border-color: #fff;
		color: #fff;
		&:hover {
			//color: @grey-lighter;
			background-color: darken(@blue, 10%);
		}
	}
}

.page-call {
	border-radius: @border-radius-base;
	background: #fff; //@blue;
	//color: #fff;
	border: solid 1px #ddd;
	//padding: 15px;
	text-align: center;
	.page-call-head {
		padding: 15px 15px 10px;
		text-align: left;
		border-bottom: solid 1px #ddd;
		.page-call-head-title {
			font-size: 18px;
			text-transform: uppercase;
			color: @grey-mid;
		}
	}
	.page-call-base {
		margin: 15px;
	}
	a.btn {
		border-color: #fff;
		color: #fff;
		padding: 8px 0px;
		//margin: 15px;
		display: block;
		&:hover {
			//color: @grey-lighter;
			background-color: darken(@blue, 10%);
		}
	}
}

.list-group {
	.sub {
		padding-left: 30px;
		font-size: 13px;
		&.active {
			background-color: #79afdd; //lighten(@grey-lightest, 5%); //lighten(@brand-light, 10%);
			border-color: #79afdd; //lighten(@grey-lightest, 5%);  //lighten(@brand-light, 10%);
			//color: @grey-mid-low;
		}
	}
}

#footer {
	background-color: @grey-dark;
	color: @grey-mid;
	.pad-lg-above();
	.pad-lg-below();

	.row > div {
		.pad-md-below();
	}

	a {
		color: @grey-mid;
		&:hover {
			color: @grey-lighter;
		}

	}

	.social {
		.social-item {
			//padding-right: 10px;
			padding-bottom: 5px;
		}
		a {
			display: inline-block;
			position: relative;
			color: @grey-mid;
			vertical-align: middle;
			height: 30px;
			padding-right: 40px;
			padding-top: 5px;
			margin-bottom: 5px;
			margin-right: 5px;
			
			&.icon-wrap {
				position:relative;
				border-radius: 100%;
				width: 30px;
				padding-left: 0px;
				padding-right: 0px;
				height: 30px;
				background-color: @brand-light;
				color: #fff;
				right: 0px;
				top: 0px;
				transition: all .4s;
				.fa {
					position: absolute;
					line-height: 1;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				&:hover {
					background-color: #fff;
					color: @grey-dark;
				}
			}
			&:hover {
				color: @grey-dark;
				.icon-wrap {
					background-color: @grey-dark;
				}
			}
			.icon-wrap {
				position: absolute;
				border-radius: 100%;
				width: 30px;
				height: 30px;
				background-color: @brand-light;
				color: #fff;
				right: 0px;
				top: 0px;
				transition: all .4s;
				.fa {
					position: absolute;
					line-height: 1;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			&:hover {
				color: @grey-dark;
				.icon-wrap {
					background-color: @grey-dark;
				}
			}
		}
		@media screen and (max-width: @screen-xs-max) {

			display: none;
			/*
			a { 
				display: inline-block;
			}
			.pad-sm-above();
			.text-noshow {
				display: none;
			}
			.social-item {
				padding-right: 10px;
				display: inline-block;
			}
			*/
		}
	}
}

#mobile-footer {
	position: fixed;
	background-color: @grey-dark;
	color: @grey-mid;
	.pad-md-above();
	.pad-md-below();
	width: 100%;
	bottom: 0px;
	left: 0px;
	z-index: 1010;
	text-align: center;
	margin-bottom: -70px;

	//transition: margin-bottom .4s;
	transition: all .4s;

	.social {
		.social-item {
			//padding-right: 10px;
			//padding-bottom: 5px;
			display: inline-block;
			padding-left: 5px;
			padding-right: 5px;
		}
		a {
			display: inline-block;
			position: relative;
			color: @grey-mid;
			vertical-align: middle;
			height: 30px;
			padding-right: 40px;
			padding-top: 5px;
			//margin-bottom: 5px;
			margin-right: 5px;
			
			&.icon-wrap {
				position:relative;
				border-radius: 100%;
				width: 30px;
				padding-left: 0px;
				padding-right: 0px;
				height: 30px;
				background-color: @brand-light;
				color: #fff;
				right: 0px;
				top: 0px;
				transition: all .4s;
				.fa {
					position: absolute;
					line-height: 1;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				&:hover {
					background-color: #fff;
					color: @grey-dark;
				}
			}
			&:hover {
				color: @grey-dark;
				.icon-wrap {
					background-color: @grey-dark;
				}
			}
			.icon-wrap {
				position: absolute;
				border-radius: 100%;
				width: 30px;
				height: 30px;
				background-color: @brand-light;
				color: #fff;
				right: 0px;
				top: 0px;
				transition: all .4s;
				.fa {
					position: absolute;
					line-height: 1;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			&:hover {
				color: @grey-dark;
				.icon-wrap {
					background-color: @grey-dark;
				}
			}
		}
		@media screen and (max-width: @screen-xs-max) {


			/*
			a { 
				display: inline-block;
			}
			.pad-sm-above();
			.text-noshow {
				display: none;
			}
			.social-item {
				padding-right: 10px;
				display: inline-block;
			}
			*/
		}
	}

	@media screen and (max-width: @screen-xs-max) {
		&.page-scrolled {
			margin-bottom: 0px;
			transition: all .4s;
		}
	}
}